<template>
  <b-row
    style="height: 80vh"
    class="d-flex align-content-center justify-content-center"
  >
    <b-col
      cols="12"
      class="d-flex justify-content-center"
    >
      <img
        src="@/assets/images/logo/logo1.png"
        alt="logo"
        style="width: 6vw; height: auto; object-fit: contain;"
      >

    </b-col>
    <b-col
      cols="12"
      class="d-flex justify-content-center py-1"
    >
      <h1
        style="font-size: 4rem; font-weight: 500; text-align: center; color: #363636;  width: 60%;"
      >
        Welcom to system Gulftic
      </h1>
    </b-col>

    <b-col
      cols="12"
      class="d-flex justify-content-center"
    >
      <div
        v-if="!loader"
        id="chart"
        style="width: 60%; background-color: white; border-radius: 18px;"
      >
        <h5 class="p-1">
          Number of Requests
        </h5>
        <apexchart
          type="bar"
          height="350"
          width="100%"
          :options="chartOptions"
          :series="series"
        />
      </div>

      <div
        v-else
        class="text-center"
      >
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem; margin-top: 2rem;"
          label="Large Spinner"
        />
      </div>
    </b-col>

  </b-row>
</template>

<script>
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import StaticCards from './components/StaticCards.vue'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      dashboard: {},
      loader: false,
      series: [{
        name: 'requests',
        data: [],
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['black'],
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            distributed: true,
          },
        },
        xaxis: {
          categories: [
            ['Jeem1'],
            ['Saber'],
            ['Sfda'],
            ['Moiat'],
            ['Sls'],
          ],
        },
      },
    }
  },
  mounted() {
    this.getAnalysis()
  },
  methods: {
    getAnalysis() {
      this.loader = true
      axios.get('/analysis').then(res => {
        const data = decryptData(res.data.payload).data?.analysis?.requests_analysis
        const jeem1Data = data.find(item => item.source === 'jeem1')
        const moiatData = data.find(item => item.source === 'moiat')
        const saberData = data.find(item => item.source === 'saber')
        const sfdaData = data.find(item => item.source === 'sfda')
        const slsData = data.find(item => item.source === 'sls')
        this.series[0].data[0] = jeem1Data.total_count
        this.series[0].data[1] = saberData.total_count
        this.series[0].data[2] = sfdaData.total_count
        this.series[0].data[3] = moiatData.total_count
        this.series[0].data[4] = slsData.total_count
        this.loader = false
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style></style>
